'use strict';
import 'bootstrap/js/dist/offcanvas';
//import aos from 'aos';
import "aos";
var AOS = require('aos');

AOS.init();
  
// Look for .hamburger
var hamburger = document.querySelector(".hamburger");
// On click
hamburger.addEventListener("click", function() {
    // Toggle class "is-active"
    hamburger.classList.toggle("is-active");
    // Do something else, like open/close menu
});

document.addEventListener('keydown', function(e){
    if (e.key === "Escape") { // escape key maps to keycode `27`
        if (hamburger.classList.contains("is-active")) {
            hamburger.classList.toggle("is-active");            
        }
   }
});

/* Scroll to Main */
var scrollDownBtn = document.querySelector(".scrollDown");
var scrollMain = document.querySelector("main").offsetTop;

scrollDownBtn.addEventListener('click', () => window.scrollTo({
  top: scrollMain,
  behavior: 'smooth',
}));

/* Scroll to Top */
var scrollTopBtn = document.querySelector(".scrollTop");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    scrollTopBtn.style.opacity = 1;
    scrollTopBtn.style.visibility = "visible";
  } else {
    scrollTopBtn.style.opacity = 0;
    scrollTopBtn.style.visibility = "hidden";
  }
}

// When the user clicks on the button, scroll to the top of the document
scrollTopBtn.addEventListener('click', () => window.scrollTo({
    top: 0,
    behavior: 'smooth',
}));

